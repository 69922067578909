import React, { useState, useEffect } from 'react';
import Registros from '../../Registros';
import Importar from './Importar';
import NuevaCampania from './NuevaCampania';
import EditarCampania from './EditarCampania';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const MySwal = withReactContent(Swal);

const Main = ({ 
    getExcelRegistros, 
    verificarWhatsapp, 
    enviarMensaje, 
    registros, 
    setRegistros, 
    headers, 
    seleccionarTodos, 
    setSeleccionarTodos, 
    getRegistros, 
    clearRegistros, 
    setHeaders, 
    campanias, 
    campaniaActiva, 
    setCampaniaActiva, 
    getCampanias
} ) => {
    const [mostrar, setMostrar] = useState('TODOS');
    const [openCampania, setOpenCampania] = useState(false);
    const [openCampanias, setOpenCampanias] = useState(false);

    const actualizarDatos = async () => {
        getCampanias();
    }

    useEffect(() => {
      getCampanias()
    }, []);

    const enviarListaMensaje = ( [ { telefono, mensaje, _id, img, mimetype }, ...otros ], callback) => {
        enviarMensaje({ telefono, mensaje, id: _id, img, mimetype });
        if(otros.length > 0){
            setTimeout(() => {
                enviarListaMensaje(otros, callback);
            }, 2000);
        } else {
            callback();
        }
    }

    const verificarWhatsapps = ( [ { telefono, mensaje, _id, img, mimetype }, ...otros ], callback) => {
        verificarWhatsapp({ telefono, mensaje, id: _id, img, mimetype });
        if(otros.length > 0){
            setTimeout(() => {
                verificarWhatsapps(otros, callback);
            }, 100);
        } else {
            callback();
        }
    }

    const enviarSeleccionados = async () => {
        const seleccionados = registros.filter(x => x.checked === true );
        const campo = campaniaActiva.campos.find( x => x.tipo === 'phone' );
        if(campo && seleccionados.length > 0){
            enviarListaMensaje(seleccionados, ()=>{
                setTimeout(() => {
                    getRegistros()
                }, 2000);
            });
        } else {
            MySwal.fire({
                title: '¡No hay campos configurado como tipo telefono!',
                icon: 'error'
            });
        }
    }

    const verificarSeleccionados = async () => {
        const seleccionados = registros.filter(x => x.checked === true );
        const campo = campaniaActiva.campos.find( x => x.tipo === 'phone' );
        if(campo && seleccionados.length > 0){
            verificarWhatsapps(seleccionados, ()=>{
                setTimeout(() => {
                    getRegistros()
                }, 2000);
            });
        } else {
            MySwal.fire({
                title: '¡No hay campos configurado como tipo telefono!',
                icon: 'error'
            });
        }
    }

    const getExcelRegistro = async () => {
        getExcelRegistros();
    }
    
    return (
        <>
            <nav className="level">
               
            </nav>
            <nav className="panel">
                <div className="panel-block">
                    <div className="control">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="field has-addons">
                                    <div className="control">
                                        <div className={'dropdown '+ (openCampanias ? 'is-active' : '')} onBlur={(e)=> { setTimeout(()=> setOpenCampanias(false), 200 )  } }>
                                            <div className="dropdown-trigger">
                                                <button className="button" aria-haspopup="true" aria-controls="dropdown-menu3" onClick={()=> setOpenCampanias(!openCampanias) }>
                                                    <span>{campaniaActiva ? campaniaActiva.nombre: 'No hay campaña activa'}</span>
                                                    <span className="icon is-small">
                                                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                                                    </span>
                                                </button>
                                            </div>
                                            <div className="dropdown-menu" id="dropdown-menu3" role="menu">
                                                <div className="dropdown-content">
                                                    { campanias.length > 0 ? campanias.map((campania)=> (
                                                    <a href="#" className="dropdown-item" key={campania._id} onClick={()=> setCampaniaActiva(campania)}>
                                                        {campania.nombre}
                                                    </a>
                                                    )): (
                                                    <a href="#" className="dropdown-item">
                                                        Sin campañas registradas
                                                    </a>
                                                    )}
                                                    <hr className="dropdown-divider" />
                                                    <a href="#" className="dropdown-item" onClick={() => setOpenCampania(true) }>
                                                        Agregar
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="control">
                                        <input className="input" type="text" placeholder="Buscar un mensaje" />
                                    </p>
                                    <p className="control">
                                        <button className="button">
                                            Buscar
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {campaniaActiva && (
                        <div className="level-right">
                            {/* <p className="level-item"><strong>Todos</strong></p>
                            <p className="level-item"><a>Enviados</a></p>
                            <p className="level-item"><a>Recividos</a></p>
                            <p className="level-item"><a>Leidos</a></p> */}
                            <EditarCampania campania={ campaniaActiva } actualizar={actualizarDatos} />
                            <Importar campaniaId={ campaniaActiva._id } actualizar={actualizarDatos} />
                            <p className="level-item"><a className="button is-warning" onClick={ verificarSeleccionados }>Verificar Whatsapp</a></p>
                            <p className="level-item"><a className="button is-success" onClick={ enviarSeleccionados }>Enviar</a></p>
                            <p className="level-item"><a className="button is-primary" onClick={ getExcelRegistro }>Exportar</a></p>
                        </div>
                    )}
                </div>
                <p className="panel-tabs">
                    <a className={mostrar == 'TODOS' ? 'is-active':'' } href="#" onClick={(e)=> { e.preventDefault(); setMostrar('TODOS'); }}>Todos</a>
                    <a className={mostrar == 'ENVIADO' ? 'is-active':''} href="#" onClick={(e)=> { e.preventDefault(); setMostrar('ENVIADO'); }}>Enviados</a>
                    <a className={mostrar == 'NO ENVIADO' ? 'is-active':''} href="#" onClick={(e)=> { e.preventDefault(); setMostrar('NO ENVIADO'); }}>No enviados</a>
                    <a className={mostrar == 'SIN WHATSAPP' ? 'is-active':''} href="#" onClick={(e)=> { e.preventDefault(); setMostrar('SIN WHATSAPP'); }}>Sin whatsapp</a>
                    <a className={mostrar == 'CON WHATSAPP' ? 'is-active':''} href="#" onClick={(e)=> { e.preventDefault(); setMostrar('CON WHATSAPP'); }}>Con whatsapp</a>
                </p>
                <Registros 
                    {
                        ...{ 
                            campaniaId: campaniaActiva ? campaniaActiva._id: null, 
                            campaniaActiva, 
                            registros: mostrar === 'TODOS' ? registros: registros.filter(x => x.estado === mostrar), 
                            setRegistros, 
                            headers, 
                            seleccionarTodos, 
                            setSeleccionarTodos, 
                            getRegistros, 
                            clearRegistros, 
                            setHeaders,
                            campanias,

                        }
                    } 
                />
            </nav>
            {/* <nav className="pagination is-small" role="navigation" aria-label="pagination">
                <a className="pagination-previous">Previous</a>
                <a className="pagination-next">Next page</a>
                <ul className="pagination-list">
                    <li><a className="pagination-link" aria-label="Goto page 1">1</a></li>
                    <li><span className="pagination-ellipsis">&hellip;</span></li>
                    <li><a className="pagination-link" aria-label="Goto page 45">45</a></li>
                    <li><a className="pagination-link is-current" aria-label="Page 46" aria-current="page">46</a></li>
                    <li><a className="pagination-link" aria-label="Goto page 47">47</a></li>
                    <li><span className="pagination-ellipsis">&hellip;</span></li>
                    <li><a className="pagination-link" aria-label="Goto page 86">86</a></li>
                </ul>
            </nav> */}
                {/* <div className={'modal '+ (openContacto ? 'is-active' : '')}>
                    <div className="modal-background"></div>
                        <div className="modal-card">
                        <header className="modal-card-head">
                            <p className="modal-card-title">Crear contacto</p>
                            <button className="delete" aria-label="close" onClick={()=> setOpenContacto(false) }></button>
                        </header>
                        <section className="modal-card-body">
                            { ((campaniaActiva && campaniaActiva.campos) || []).map((campo,i) => (
                            <div className="field" key={i}>
                                <label className="label">{campo.titulo}</label>
                                <div className="control">
                                <input className="input" type={campo.tipo} placeholder={campo.titulo} name={campo.name} onChange={ (e ) => setCampos([...campos.map((x,index) => index == i ? { ...x, valor: e.target.value }: x )])  } />
                                </div>
                            </div>
                            ))}
                        </section>
                        <footer className="modal-card-foot">
                            <button className="button is-success" onClick={handleSave} >Guardar</button>
                            <button className="button" onClick={()=> setOpenContacto(false) }>Cancel</button>
                        </footer>
                    </div>
                </div> */}
                <NuevaCampania setOpen={(option) => setOpenCampania(option) } open={openCampania} actualizar={actualizarDatos} />
            </>
    );
}

export default Main;