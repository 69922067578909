
import { useEffect, useState } from "react";
import { io } from 'socket.io-client';
import { URL_SOCKET } from "../constanst";
const socket = io(URL_SOCKET, { autoConnect: false });

const useSocket = () => {
    const [sesion, setSesion] = useState(null)

    useEffect(() => {
        socket.connect();
        socket.on("connect", () => {
            console.log("Connected to server successfully");
        });
        
        socket.on("connect_error", (err) => {
          console.log(`connect_error due to ${err.message}`);
        });
  
        socket.on('mensaje-added', async () => { })
    
        socket.on('message', (message) => {
            console.log(message);
        })
    
        socket.on('session', (qr) => {
            setSesion(qr);
        })
        
        socket.on("disconnect", () => {
          console.log("Disconnected from server");
        });
      
        return () => {
          console.log("Disconnected from server");
          socket.disconnect();
        }
    }, []);

    const logout = () => {
        socket.emit('logout');
    }


    const reset = () => {
        socket.emit('logout');
    }

    const enviarMensaje = ({ telefono, mensaje, id, img, mimetype }) =>{
        console.log({ mensaje, telefono, id, img, mimetype });
        socket.emit('enviarMensaje',{ telefono, mensaje, id, img, mimetype });
    }

    const verificarWhatsapp = ({ telefono, mensaje, id, img, mimetype }) =>{
        console.log({ mensaje, telefono, id, img, mimetype });
        socket.emit('verificarWhatsapp',{ telefono, mensaje, id, img, mimetype });
    }

    return {
        socket, 
        sesion,
        logout,
        reset,
        enviarMensaje,
        verificarWhatsapp
    };
}

export default useSocket;