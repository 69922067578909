import { useState } from "react";
import { io } from 'socket.io-client';
import axios from 'axios'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { URL_API } from "../../constanst";
const MySwal = withReactContent(Swal);

const NuevaCampania = ( { open, setOpen, actualizar }) => {
    const [nombreCampania, setNombreCampania] = useState('');
    const [mensajeCampania, setMensajeCampania] = useState('');
    const [file, setFile] = useState(null);
    const [nameFile, setNameFile] = useState('');
    const [campos, setCampos] = useState([
    ]);

    const handleFile = (e) => {
        if(e.target.files.length > 0){
          setFile(e.target.files[0]);
          setNameFile(e.target.files[0].name);
        }
    }
    
    const handleSave = async (e) => {
        const formData = new FormData();
        formData.append("adjunto", file);
        formData.append("mensaje", mensajeCampania);
        formData.append("nombre", nombreCampania);
        formData.append("campos", JSON.stringify(campos));
        await axios.post(`${URL_API}/campanias`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
        MySwal.fire({
          title: '¡Campaña agregada con exito!',
          icon: 'success'
        });
        setOpen(false);
        actualizar();
    }

    return (
        <>
            <div className={'modal '+ (open ? 'is-active' : '')}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Crear campaña</p>
                        <button className="delete" aria-label="close" onClick={()=> setOpen(false) }></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="field">
                            <label className="label">Nombre</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="Nombre" value={nombreCampania} onChange={(e) => setNombreCampania(e.target.value)}/>
                            </div>
                        </div>
                        <div className="box">
                            { campos.map((campo,i) => (
                                <div className="field has-addons" key={i}>
                                <p className="control">
                                    <span className="select" >
                                        <select value={campo.tipo} onChange={ (e ) => setCampos([...campos.map((x,index) => index == i ? { ...x, tipo: e.target.value }: x )])  }>
                                            <option value="text">Texto</option>
                                            <option value="numeric">Numerico</option>
                                            <option value="currency">Moneda</option>
                                            <option value="phone">Teléfono</option>
                                            <option value="date">Fecha</option>
                                        </select>
                                    </span>
                                </p>
                                <p className="control">
                                    <input className="input" type="text" placeholder="Campo"  value={campo.campo} onChange={ (e ) => setCampos([...campos.map((x,index) => index == i ? { ...x, campo: e.target.value }: x )])  } />
                                </p>
                                <p className="control">
                                    <input className="input" type="text" placeholder="Titulo"  value={campo.titulo} onChange={ (e ) => setCampos([...campos.map((x,index) => index == i ? { ...x, titulo: e.target.value }: x )])  } />
                                </p>
                                <p className="control">
                                    <input className="input" type="text" placeholder="Valor"  value={campo.valor} onChange={ (e ) => setCampos([...campos.map((x,index) => index == i ? { ...x, valor: e.target.value }: x )])  } />
                                </p>
                                <p className="control">
                                    <a className="button is-danger" disabled={campo.base} onClick={() => setCampos(campos.filter((x,index) => index !== i ))}>
                                    X
                                    </a>
                                </p>
                                </div>
                            ))}
                            <button className="button is-primary is-fullwidth" onClick={() => setCampos([...campos, { tipo: 'text', campo: '', titulo: '', valor: ''}])}>Agregar campo</button>
                        </div>
                        <div className="field">
                            <label className="label">Mensaje</label>
                            <div className="control  ">
                                <textarea className="input textarea " placeholder="Textarea" rows={5} value={mensajeCampania} onChange={(e) => setMensajeCampania(e.target.value)}></textarea>
                            </div>
                            <p className="help">Escriba entre corchetes el nombre del campo personalizado. Ejemplo: [Campo]</p>
                        </div>
                        <div className="field">
                            <label className="label">Imagen adjunta</label>
                            <div className="file has-name is-fullwidth">
                                <label className="file-label">
                                <input className="file-input" type="file" name="resume" onChange={handleFile} />
                                <span className="file-cta">
                                    <span className="file-icon">
                                        <i className="fas fa-upload"></i>
                                    </span>
                                    <span className="file-label">
                                        Seleccione un imagen
                                    </span>
                                </span>
                                <span className="file-name">
                                    {nameFile}
                                </span>
                                </label>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-success" onClick={handleSave} >Guardar</button>
                        <button className="button" onClick={()=> setOpen(false) }>Cancel</button>
                    </footer>
                </div>
            </div>
        </>
    );
}

export default NuevaCampania;