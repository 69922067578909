import { useEffect } from "react";
import Registro from './Registro'

const Registros = ({ campaniaActiva, registros, setRegistros, headers, seleccionarTodos, setSeleccionarTodos }) => {
  
  const handleSeleccionarTodos = async (e) => {
    const { checked } = e.target;
    setSeleccionarTodos(checked);
    setRegistros( registros.map( x => ({...x, checked })) );
  }

  const handleCheckboxRegistro = async (e, index) => {
    const { checked } = e.target;
    setRegistros( registros.map( (x,i) => (i === index ? ({...x, checked }): x )) );
  }

  return (
    <div className="table-container">
      <table className="table is-striped is-narrow is-hoverable is-fullwidth">
          <thead>
            <tr>
                <th><input type="checkbox" checked={seleccionarTodos} onChange={ handleSeleccionarTodos }/></th>
                { headers.map(( header, i) => ( <th key={ i }>{ header }</th> ))}
                <th>Estatus</th>
            </tr>
          </thead>
          <tbody>
            {
              registros && registros.length > 0 && campaniaActiva  ? registros.map((order,i) => <Registro key={i} index={i} registro={order} campos={campaniaActiva.campos} handleCheckbox={handleCheckboxRegistro} /> ): 
              <tr><td colSpan={ headers.length + 2} className="is-size-4 has-text-centered"> Sin registros importados</td></tr>
            }
          </tbody>
      </table>
    </div>
  )
}

export default Registros;