
import { useState, useEffect } from "react";
import axios from "axios";
import Mustache from 'mustache';
import { format } from '../utils';
import { URL_API } from "../constanst";

const useRegistros = () => {
    const [registros, setRegistros] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [seleccionarTodos, setSeleccionarTodos] = useState(false);
    const [campanias, setCampanias] = useState([]);
    const [campaniaActiva, setCampaniaActiva] = useState(null);
    const [urlExcel, setUrlExcel] = useState(null);

    const getCampanias = async () => {
        setCampanias([]);
        setCampaniaActiva(null);
        const response = await axios.get(`${URL_API}/campanias`)
        setCampanias(response.data);
        setCampaniaActiva(response.data.find(x => x.activa ));
    }
    const getExcelRegistros = async () => {
        const response = await axios.get(`${URL_API}/archivos/campania/excel/${campaniaActiva._id}`);
        if(response?.data?.url)
           window.open(`${URL_API}/${response.data.url}`, '_blank');
    }

    const getRegistros = async ( ) => {
        const response = await axios.get(`${URL_API}/archivos/campania/${campaniaActiva._id}`)
        const datos = response && response.data.length > 0 && response.data[0].registros ? response.data[0].registros : [];
        if(datos.length > 0) {
            const campoTelefono = campaniaActiva.campos.find( x => x.tipo === 'phone' );
            setRegistros(
                datos.map(x =>{
                    const telefono = x.datos.find(y => y.campo == (campoTelefono ? campoTelefono.campo: ''));
                    const regJson = x.datos.reduce((a,b) => {
                        const campo = campaniaActiva.campos.find( x => x.campo === b.campo );
                        let valor = b.valor;
                        if(campo && campo.tipo == 'currency'){
                            valor = format(valor);
                        }
                        return {...a, [b.campo.trim()] : valor };
                    },{});
                    var customTags = [ '[', ']' ];
                    return ({
                        ...x,
                        checked: false, 
                        telefono: telefono ? telefono.valor: '', 
                        mensaje: Mustache.render(campaniaActiva.mensaje, regJson,{},customTags ),
                        img: campaniaActiva.adjunto, 
                        mimetype: campaniaActiva.adjuntoMimeType
                    });
                })
            );
        }
    }

    const clearRegistros = async () => {
        setHeaders([]);
        setRegistros([]);
    }

    useEffect(() => {
        if(campaniaActiva) {
            setHeaders(campaniaActiva.campos.map((x) => x.titulo ));
            getRegistros();
        }
        else 
        clearRegistros();
    }, [campaniaActiva])

    return { 
        registros, 
        headers, 
        seleccionarTodos, 
        setRegistros, 
        setHeaders, 
        seleccionarTodos, 
        setSeleccionarTodos, 
        getRegistros, 
        clearRegistros, 
        campanias, 
        setCampanias, 
        campaniaActiva, 
        setCampaniaActiva, 
        getCampanias,
        getExcelRegistros
    };    
}

export default useRegistros;