import React, { useEffect } from 'react';
import 'bulma/css/bulma.min.css';

import Main from './pages/Main';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import useSocket from './hooks/useSocket';
import useRegistros from './hooks/useRegistros';

function About() {
  return <h2>About</h2>;
}

function Dashboard() {
  return <h2>Dashboard</h2>;
}

const App = () => {
  const { 
    registros, 
    setRegistros, 
    headers, 
    seleccionarTodos, 
    setSeleccionarTodos, 
    getRegistros, 
    clearRegistros, 
    setHeaders, 
    campanias, 
    campaniaActiva, 
    setCampaniaActiva, 
    getCampanias,
    getExcelRegistros
  } = useRegistros();
  const { logout, reset, sesion, enviarMensaje, verificarWhatsapp } = useSocket();

  const handleLogout = () => {
    logout();
    window.location.reload();
  }
  return (
    <Router>
      <div className="main"> 
        <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <a className="navbar-item" href="/">
              WhatsApps Masivos
            </a>
          </div>
            <div className="navbar-end">
              <div className="navbar-item">
                <div className="buttons">
                  { sesion && sesion.usuario_logeado ? (
                    <>
                      {/* <Link to="/" className="button is-primary">
                        <strong>Contactos</strong>
                      </Link>
                      */}
                      <a href="#" className="button is-primary">
                        <strong>{sesion.username}</strong>
                      </a> 
                      <a href="#" className="button is-light" onClick={handleLogout}>
                        Cerrar sesión
                      </a> 
                    </>
                  ) : (
                    <a href="#" className="button is-light" onClick={handleLogout}>
                      Resetar
                    </a> 
                  ) }

              </div>
            </div>
          </div>
        </nav>
        <section className="hero bd-hero bd-is-basic">
          <div className="hero-body">
            <div className="bd-hero-body">
              <div className="bd-hero-heading">
                <h1 className="title algolia-lvl0">
                  <p>Whatsapps Masivo</p>
                </h1>
                {/* <div className="subtitle is-4 algolia-lvl1">
                  <p>Herramienta para envio <strong>masivo</strong> de mensajes</p>
                </div> */}
              </div>
            </div>
          </div>
        </section>
        <div className="container">
          { !sesion ? (
            <div>Cargando...</div>
          ) : (
            sesion && sesion.qr && !sesion.usuario_logeado  ? (
              <div className="columns">
                <div className="column is-half is-offset-one-quarter">
                  <div className="card">
                  <div className="card-image">
                    <figure className="image is-4by3">
                      <img src={sesion.qr} alt="img" style={{ width: 'auto', margin: 'auto' }}/>
                    </figure>
                  </div>
                  <div className="card-content">
                    <div className="content">
                      <ul>
                        <li>Entra a whatsapp</li>
                        <li>Ve a Dispositivos vinculados</li>
                        <li>Vincular un dispositivo</li>
                        <li>Escanea el codigo QR</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            ) : (
              <Routes>
                <Route exact path="/" element={
                  <Main {
                    ...{ 
                      logout, 
                      sesion, 
                      enviarMensaje, 
                      verificarWhatsapp, 
                      registros, 
                      setRegistros, 
                      headers, 
                      seleccionarTodos, 
                      setSeleccionarTodos, 
                      getRegistros, 
                      clearRegistros, 
                      setHeaders, 
                      campanias, 
                      campaniaActiva, 
                      setCampaniaActiva, 
                      getCampanias,
                      getExcelRegistros
                    } 
                  }/>
                }>
                </Route>
                <Route path="/about" element={<About />}>
                </Route>
                <Route path="/dashboard" element={<Dashboard />}>
                </Route>
              </Routes>
            )
          )}
        </div>
      </div>
    </Router>
  );
}

export default App;
