import { useState } from "react";
import { io } from 'socket.io-client';
import axios from 'axios'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { URL_API } from "../../constanst";

const MySwal = withReactContent(Swal);

const Mensajes = ({ campaniaId, actualizar }) => {
    const [open, setOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [nameFile, setNameFile] = useState('');

    const handleFile = (e) => {
        if(e.target.files.length > 0){
          setFile(e.target.files[0]);
          setNameFile(e.target.files[0].name);
        }
    }
    
    const handleSave = async (e) => {
        const formData = new FormData();
        formData.append("excel", file);
        formData.append("campaniaId", campaniaId);
        await axios.post(`${URL_API}/archivos`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
        MySwal.fire({
          title: '¡Archivo importado a la campaña con exito!',
          icon: 'success'
        });
        setOpen(false);
        actualizar();
    }

    return (
        <>
            <p className="level-item"><a className="button is-info" onClick={()=> setOpen(true) }>Importar</a></p>
            <div className={'modal '+ (open ? 'is-active' : '')}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head">
                        <p className="modal-card-title">Importar informacion</p>
                        <button className="delete" aria-label="close" onClick={()=> setOpen(false) }></button>
                    </header>
                    <section className="modal-card-body">
                        <div className="file has-name is-fullwidth">
                            <label className="file-label">
                                <input className="file-input" type="file" name="resume" onChange={handleFile} />
                                <span className="file-cta">
                                    <span className="file-icon">
                                        <i className="fas fa-upload"></i>
                                    </span>
                                    <span className="file-label">
                                        Seleccione un excel
                                    </span>
                                </span>
                                <span className="file-name">
                                    {nameFile}
                                </span>
                            </label>
                        </div>
                    </section>
                    <footer className="modal-card-foot">
                        <button className="button is-success" onClick={handleSave} >Guardar</button>
                        <button className="button" onClick={()=> setOpen(false) }>Cancel</button>
                    </footer>
                </div>
            </div>
        </>
    );
}

export default Mensajes;