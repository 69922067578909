import React from 'react'
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

export default function Registro({ registro, campos, handleCheckbox, index }) {  
  return (
    <tr>
        <td>
          <input type="checkbox" onChange={(e) => handleCheckbox(e , index )} checked={registro.checked} />
        </td>
        { campos.map((campo,i) => {
          const valor = registro.datos.find(x => x.campo === campo.campo );
          return ( <td key={i}>{valor ? valor.valor : '' }</td> );
        }) }
        <td>
          <Popup trigger={<div>{registro.estado}</div>} on={['hover', 'focus']} position="left center">
            { registro.mensaje }
          </Popup>
        </td>
    </tr>
  )
}